import React, { FunctionComponent } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Contact: FunctionComponent = () => (
  <Layout>
    <SEO title="Contact Us" />

    <div className="container mx-auto mt-4 px-4 lg:mt-16">
      <h1 className="font-Parisienne mb-2 text-4xl md:text-6xl">
        Contact Us
      </h1>

      <p className="font-light">
        Send us a message and we’ll get back to you as soon as possible. You can also reach us by
        phone at
        {' '}
        <a href="tel:7035282223">(703) 528-2223</a>
        {'. '}
        Looking forward to hearing from you!
      </p>

      <div className="my-8">
        <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <div className="gap-8 grid mb-8 sm:grid-cols-2">
            <div>
              <input
                className="appearance-none border-2 border-grey px-4 py-3 rounded-md w-full"
                name="first-name"
                placeholder="First Name"
                required
                type="text"
              />
            </div>

            <div>
              <input
                className="appearance-none border-2 border-grey px-4 py-3 rounded-md w-full"
                name="last-name"
                placeholder="Last Name"
                required
                type="text"
              />
            </div>
          </div>

          <div className="gap-8 grid mb-8 sm:grid-cols-2">
            <div>
              <input
                className="appearance-none border-2 border-grey px-4 py-3 rounded-md w-full"
                name="email-address"
                placeholder="Email Address"
                required
                type="email"
              />
            </div>

            <div>
              <input
                className="appearance-none border-2 border-grey px-4 py-3 rounded-md w-full"
                name="phone-number"
                placeholder="Phone Number"
                required
                type="tel"
              />
            </div>
          </div>

          <textarea
            className="appearance-none border-2 border-grey px-4 py-3 rounded-md w-full"
            name="message"
            placeholder="Provide your message here. If inquiring about catering, please provide date and size of event."
            rows={8}
          />

          <div className="mt-8 text-right">
            <button
              className="bg-green inline-block px-6 py-2 rounded-md text-white transition-opacity hover:opacity-70"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>

    <div className="bg-beige">
      <div className="container mx-auto px-4 py-12">
        <div className="gap-16 grid items-center lg:grid-cols-2">
          <div className="mx-auto relative">
            <iframe
              className="max-w-full relative shadow-md z-10"
              height="450"
              loading="lazy"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDP8goJrDvENYjSXSeCtnzeI8J7Xb1tiqU&amp;q=Natural+Cafe+Market,Arlington+VA"
              title="Location Map"
              width="600"
            />

            <div className="absolute bg-green h-24 -left-4 rounded-md w-24 -top-4" />
            <div className="absolute bg-green -bottom-4 h-24 -right-4 rounded-md w-24" />
          </div>

          <div className="text-center lg:text-left">
            <h3 className="text-green uppercase">Location &amp; Hours</h3>

            <div className="font-light grid md:grid-cols-2">
              <div>
                <h2 className="font-Parisienne my-4 text-4xl">Hours of Operation</h2>

                <div className="mb-4">
                  <p>Monday - Thursday</p>
                  <p>6:30am - 10:30pm</p>
                </div>

                <div className="mb-4">
                  <p>Friday</p>
                  <p>6:30am - 10:30pm</p>
                </div>

                <div className="mb-4">
                  <p>Saturday</p>
                  <p>8:00am - 10:30pm</p>
                </div>

                <div className="mb-4">
                  <p>Sunday</p>
                  <p>8:00am - 10:00pm</p>
                </div>
              </div>

              <div>
                <h2 className="font-Parisienne my-4 text-4xl">Address</h2>

                <p>850 N Randolph Street #119</p>
                <p>Arlington, VA 22203</p>

                <h2 className="font-Parisienne my-4 text-4xl">Contact</h2>

                <a href="tel:7035282223">(703) 528-2223</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
